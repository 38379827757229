import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1077.000000 1083.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1083.000000) scale(0.100000,-0.100000)">
		<path d="M0 5400 l0 -5400 5385 0 5385 0 0 5400 0 5400 -5385 0 -5385 0 0
-5400z m5990 5186 c310 -37 535 -83 865 -176 187 -53 493 -163 640 -230 663
-304 1138 -632 1617 -1117 316 -320 539 -607 770 -993 407 -678 666 -1496 719
-2275 13 -199 13 -603 -1 -794 -53 -728 -290 -1513 -648 -2146 -312 -551 -732
-1058 -1197 -1444 -55 -46 -110 -91 -122 -102 -86 -72 -378 -274 -523 -361
-465 -279 -965 -487 -1445 -602 -60 -15 -123 -30 -140 -35 -16 -5 -75 -17
-130 -26 -55 -9 -102 -18 -105 -20 -4 -2 -62 -11 -131 -20 -68 -9 -137 -18
-154 -21 -259 -38 -945 -38 -1215 1 -19 2 -84 12 -145 20 -60 9 -119 18 -130
20 -11 3 -47 9 -80 14 -103 17 -359 78 -490 117 -145 44 -340 110 -343 117 -2
4 -10 7 -17 7 -34 0 -317 121 -516 220 -339 170 -689 396 -954 614 -27 23 -61
50 -74 61 -165 135 -484 455 -642 645 -532 640 -906 1396 -1083 2190 -37 164
-64 311 -76 413 -6 48 -15 109 -20 135 -31 165 -38 877 -11 1107 71 604 182
1035 396 1545 436 1035 1244 1937 2235 2495 649 366 1408 600 2120 655 177 13
881 4 1030 -14z"/>
<path d="M6118 7023 c-23 -32 -47 -63 -53 -69 -5 -7 -35 -44 -65 -84 -30 -40
-96 -124 -145 -189 -183 -236 -345 -450 -345 -455 0 -3 354 -6 788 -6 464 0
824 -4 877 -10 445 -50 787 -313 860 -661 46 -216 -3 -444 -131 -610 -132
-172 -335 -290 -596 -345 -98 -21 -980 -33 -1003 -14 -13 11 -15 93 -15 596
l0 584 -1417 -2 -1418 -3 -56 -29 c-284 -143 -283 -523 2 -673 l54 -28 985 -5
985 -5 5 -643 c4 -557 7 -644 20 -652 9 -6 350 -9 905 -6 766 2 907 5 1014 20
234 32 433 85 629 168 575 243 959 703 1058 1268 22 128 22 390 -1 525 -43
257 -180 545 -347 731 -265 294 -612 492 -1033 589 -249 57 -269 58 -912 62
l-601 4 -44 -58z"/>
<path d="M2495 3412 c-102 -43 -140 -102 -133 -205 5 -82 36 -129 105 -163 45
-22 63 -26 120 -22 40 2 88 13 116 25 l47 22 0 90 0 91 -85 0 -85 0 0 -40 c0
-37 3 -41 30 -46 37 -8 39 -21 4 -34 -42 -16 -64 -12 -95 19 -23 24 -29 38
-29 74 0 83 66 119 147 80 l35 -16 30 33 c16 19 27 41 26 49 -4 22 -104 60
-153 60 -22 0 -58 -8 -80 -17z"/>
<path d="M7315 3425 c-5 -2 -29 -9 -52 -15 -102 -26 -134 -130 -59 -191 12
-10 49 -25 84 -35 63 -17 89 -35 77 -54 -11 -18 -75 -11 -127 16 -41 21 -53
23 -60 12 -15 -22 -38 -67 -38 -73 0 -12 106 -53 155 -59 138 -19 237 68 190
167 -9 19 -28 40 -43 47 -15 7 -34 16 -42 20 -9 4 -31 11 -50 15 -67 14 -82
45 -21 45 41 0 102 -18 112 -32 6 -10 34 28 49 67 11 29 -2 39 -76 55 -80 17
-89 18 -99 15z"/>
<path d="M2914 3383 c-9 -21 -45 -106 -80 -190 -35 -84 -64 -155 -64 -158 0
-3 27 -5 60 -5 56 0 60 2 75 29 14 28 17 29 85 29 66 0 71 -2 83 -27 11 -23
18 -26 75 -29 34 -2 62 1 62 6 0 13 -19 64 -88 232 l-59 145 -67 3 -67 3 -15
-38z m103 -157 c15 -46 11 -56 -27 -56 -39 0 -43 11 -24 60 20 48 33 47 51 -4z"/>
<path d="M3245 3408 c-3 -7 -4 -95 -3 -193 l3 -180 62 -3 62 -3 3 48 c3 44 5
48 28 48 20 0 30 -10 50 -47 l25 -48 67 0 c60 0 67 2 61 18 -4 9 -9 19 -12 22
-26 22 -51 100 -33 100 19 0 42 61 42 112 0 57 -20 91 -70 118 -37 21 -278 27
-285 8z m230 -133 l0 -40 -52 -3 -53 -3 0 46 0 46 53 -3 52 -3 0 -40z"/>
<path d="M3660 3413 c-1 -5 -2 -91 -3 -193 l-2 -185 63 -3 62 -3 0 44 c0 29 7
53 19 68 11 13 21 22 23 19 3 -3 21 -33 42 -67 l37 -63 75 0 75 0 -27 48 c-15
26 -33 54 -39 62 -6 8 -26 39 -44 68 -35 58 -35 57 17 112 14 14 38 42 54 63
l30 37 -70 0 -70 0 -34 -42 c-53 -68 -71 -88 -80 -88 -4 0 -8 29 -8 65 l0 65
-60 0 c-33 0 -60 -3 -60 -7z"/>
<path d="M4090 3280 c0 -133 1 -142 25 -178 29 -44 79 -71 146 -79 39 -5 58
-1 103 21 81 40 96 78 96 246 l0 130 -65 0 -65 0 0 -128 c0 -112 -2 -131 -18
-145 -22 -20 -52 -22 -75 -4 -14 10 -17 34 -19 142 l-3 130 -62 3 -63 3 0
-141z"/>
<path d="M4490 3409 c0 -5 28 -93 62 -195 l61 -184 67 0 67 0 12 48 c7 26 19
75 28 110 8 34 19 62 23 62 5 0 11 -19 15 -42 3 -24 10 -54 15 -68 5 -14 14
-44 20 -68 l11 -43 67 3 66 3 63 185 c35 102 63 188 63 192 0 12 -118 9 -126
-3 -6 -10 -13 -38 -47 -189 -15 -63 -22 -57 -42 32 -7 35 -19 87 -27 116 l-13
53 -64 -3 -64 -3 -13 -55 c-7 -30 -19 -81 -26 -113 -7 -32 -18 -58 -23 -59 -6
0 -15 21 -22 48 -6 27 -19 79 -28 117 l-17 67 -64 0 c-38 0 -64 -4 -64 -11z"/>
<path d="M5245 3388 c-8 -18 -33 -78 -56 -133 -23 -55 -49 -118 -59 -140 -38
-87 -39 -85 29 -85 56 0 61 2 75 29 14 27 19 28 85 30 67 2 70 1 83 -26 12
-26 18 -28 77 -31 l63 -3 -7 28 c-3 15 -15 45 -24 68 -10 22 -30 72 -45 110
-15 39 -31 79 -36 90 -5 11 -16 37 -24 58 l-15 37 -66 0 c-64 0 -66 -1 -80
-32z m102 -162 c15 -46 11 -56 -27 -56 -39 0 -43 11 -24 60 20 48 33 47 51 -4z"/>
<path d="M5577 3413 c-4 -3 -7 -91 -7 -195 l0 -188 120 0 c174 0 232 30 266
137 16 51 16 58 0 109 -23 72 -71 119 -137 133 -55 12 -232 15 -242 4z m215
-104 c27 -15 51 -74 43 -110 -9 -41 -60 -81 -99 -77 l-31 3 -3 98 -3 97 36 0
c20 0 45 -5 57 -11z"/>
<path d="M6117 3393 c-8 -16 -38 -84 -66 -153 -28 -69 -58 -137 -66 -152 -28
-49 -21 -58 44 -58 55 0 61 2 73 28 12 25 18 27 81 30 66 3 68 2 85 -27 16
-29 20 -31 80 -31 73 0 72 -11 14 130 -19 47 -42 105 -52 130 -10 25 -26 64
-36 88 l-17 42 -63 0 c-59 0 -64 -2 -77 -27z m93 -157 c7 -24 9 -49 5 -55 -8
-14 -52 -14 -60 -1 -7 12 26 110 36 105 3 -3 12 -24 19 -49z"/>
<path d="M6370 3370 l0 -50 60 0 60 0 0 -145 0 -145 60 0 60 0 0 145 0 145 55
0 55 0 0 50 0 50 -175 0 -175 0 0 -50z"/>
<path d="M6843 3408 c-5 -7 -19 -35 -31 -63 -89 -207 -122 -288 -122 -300 0
-12 14 -15 58 -15 53 0 58 2 74 31 17 29 19 30 85 27 63 -3 69 -5 81 -30 12
-26 17 -28 78 -28 35 0 64 2 64 4 0 6 -99 254 -119 301 -10 22 -22 50 -25 63
-7 20 -14 22 -70 22 -39 0 -67 -5 -73 -12z m77 -154 c0 -8 5 -23 12 -33 17
-28 3 -51 -33 -51 -29 0 -31 2 -26 28 10 48 27 83 37 77 5 -3 10 -13 10 -21z"/>
<path d="M7530 3290 c0 -112 3 -135 22 -174 29 -60 83 -90 163 -91 75 0 126
23 160 73 24 35 25 43 25 180 l0 143 -62 -3 -63 -3 -5 -135 -5 -135 -27 -9
c-20 -7 -33 -5 -52 8 -26 16 -26 18 -26 146 l0 130 -65 0 -65 0 0 -130z"/>
<path d="M7960 3225 l0 -195 136 0 c121 0 141 2 174 21 40 22 50 42 50 95 0
35 -33 84 -57 84 -10 0 -5 10 16 29 23 22 31 38 31 63 0 71 -65 97 -242 98
l-108 0 0 -195z m218 73 c-3 -20 -10 -23 -49 -26 -45 -3 -47 -2 -45 22 1 24 5
26 49 26 44 0 48 -2 45 -22z m-4 -121 c10 -7 16 -22 14 -33 -3 -16 -12 -19
-53 -19 -52 0 -52 1 -46 55 2 15 64 12 85 -3z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
